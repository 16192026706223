import React, { useState, useEffect } from "react";
import { Row,Col, Table, Button, Modal, notification} from "antd";
import moment from 'moment';
import { getBuyerstApi, deleteBuyerApi} from "../../services/buyerApi";
import { useNavigate } from "react-router-dom";
import { useDispatch} from 'react-redux';
import {setBuyersListData, setBuyerTitle} from "../../reducers/buyerSlice";
import { LocalStorageService } from "../../helpers/LocalStorage";

function CorporationsList() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buyerList, setBuyerList] = useState<any>();
  const [deleteBuyer, setDeleteBuyer] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);

  const buyerLimitRisk = (row:any) => {
    navigate(`/buyer-user-limit-update/${row?.id}`)
    dispatch(setBuyerTitle(row.title))
  }

  const farmerLimitRisk = (row:any) => {
    navigate(`/farmer-user-limit-update/${row?.id}`)
    dispatch(setBuyerTitle(row.title))
  }

  const columns : any = [
    {
      title: 'Alıcı Ünvan',
      dataIndex: 'title',
      key: "title",
      fixed: 'left',
    },
    {
      title: 'VKN',
      dataIndex: 'taxId',
      key: "taxId",
    },
    {
      title: 'Adres',
      dataIndex: 'address',
      key: "address",
    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdDate',
      key: "createdDate",
      render: (date:any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title:'',
      dataIndex:'update',
      key:'update', 
      width:120,    
      render: (text: any, row: any) => 
        <Button 
          type="primary"
          className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
          onClick={()  => navigate(`/buyer-update/${row?.id}`)}
        > 
          Güncelle
        </Button>           
      },
      {
        title:'',
        dataIndex:'limitUpdate',
        key:'limitUpdate',    
        width:180, 
        render: (text: any, row: any) => 
        (
          row.typeCode === 'TEDARIKCI_ALICISI' ?
            <Button 
            type="primary"
            style={{width:'151px'}}
            onClick={()  => buyerLimitRisk(row)}
          > 
            Tedarikçi Limit/Risk
          </Button>   
        : 
          <Button 
          type="primary"
          style={{width:'151px'}}
          onClick={()  => farmerLimitRisk(row)}
        > 
          Çitfçi Limit/Risk
        </Button>  

        )        
      },
      {
        title:'',
        dataIndex:'users',
        key:'users',  
        width:120,   
        render: (text: any, row: any) => 
           <Button 
              type="primary"
              onClick={()  => navigate(`/buyer-users-list/${row?.id}`)}
              > 
              Kullanıcılar
            </Button>         
    },
    {
      title:'',
      dataIndex:'sil',
      key:'sil',     
      render: (text: any, row: any) =>  
        <Button 
          type="primary" 
          className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
          onClick={ () =>  showModal(row.id) }
          > Sil
        </Button>         
    },
  ];

  const deleteBuyerData =  async () => {
    if(deleteBuyer){
      try {
        await deleteBuyerApi(deleteBuyer);
        notification.success({
          message: `Kayıt silinmiştir.`,
        }); 
         getBuyerList(psize,page);
        
        } catch (error: any) {
          console.log(error);
        }
    }
}
  
const getBuyerList =  async (page:any,psize:any) => {
    try {
      setLoading(true);
      const response: any = await getBuyerstApi(psize,page);
      if (response) {
        setLoading(false);
        setBuyerList(response.buyers.data);
        setTotalDataCount(response.buyers.totalDataCount);
        dispatch(setBuyersListData(response.buyers.data));
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }

  const showModal = (id:any) => {
    setOpen(true);
    setDeleteBuyer(id)
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
    deleteBuyerData();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };

  useEffect(() => {
    getBuyerList(page,psize);
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [page,psize])

 

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Alıcılar Listesi</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Table
           className="w-100 invoiceTable"
           pagination={{
             position: ["topRight", "none" as any],
             showLessItems: true,                          
             showSizeChanger: true,
             current: page,
             pageSize:psize,
             total:totalDataCount, 
             onChange: handleShowSize,
             
           }}
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={buyerList}
          scroll={{
            x: 1200,
          }}
          
        />
        </Col>
      </Row>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
      >
        <p>İşlemi yapmak istediğinizden emin misiniz?</p>
      </Modal>
    </main>
  );
};


export default CorporationsList;
