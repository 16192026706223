import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, Select, Checkbox, notification, Spin} from "antd";
import { useSelector} from 'react-redux';
import { createBuyerIdentifyApi} from "../../services/buyerApi";
import {getTaxAdministartions,getDistrictsApi} from "../../services/commonApi"
import {getCorporationsListApi} from "../../services/financeApi";
import {validateVkn} from "../../helpers/validators";

function Identification() {

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [financial, setFinancial] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]);
  const [taxAdministartion, setTaxAdministartion] = useState<any>([]);
  const {provinces} = useSelector((state: any) => state.common);

  const [check, setCheck] = useState<any>(false);
  const [checkIntegration, setCheckIntegration] = useState<any>(false);
  const [state, setState] = useState<any>([]);

  const handleInputChange = (id:any, value:any) => {
    setState((prevState:any) =>
      prevState.map((item:any) =>
        item.id === id
          ? { ...item, limit: value, isChecked: value !== "" } 
          : item
      )
    );
  };

  const handleCheckboxChange = (id:any, checked:any) => {
    setState((prevState:any) =>
      prevState.map((item:any) =>
        item.id === id ? { ...item, isChecked: checked } : item
      )
    );
  };

  const onChange = (checkedValues:any) => {
    setCheck( checkedValues.target.checked)
  };

  const onChangeIntegration = (checkedValues:any) => {
    setCheckIntegration( checkedValues.target.checked)
  }; 

  const onFinish = async (values: any) => {
    const financialID2: any = [];
    let notificationMessage = "";

    Object.keys(values)
      .filter((key) => key.endsWith("limit"))
      .forEach((limitKey) => {
        const index: any = limitKey.match(/^\d+/)?.[0];
        const companyKey = `${index}company`;
        const limitValue = values[limitKey];
        const companyValue = values[companyKey];

        const entry: any = { financialInstitutionId: financial[index].id, limit: limitValue };

        if (companyKey in values) {
          if (values[companyKey] === undefined && values[limitKey] !== undefined) {
            notificationMessage += `${index}`;
          } else {
            entry.companyCode = companyValue;
          }
        }
        financialID2.push(entry);
      });

    if (notificationMessage) {
      notification.error({
        message: `CompanyCode alanlarını boş bırakamazsınız. `,
      });
    } else {
       setLoading(true);
      try {
        let financialID: any = [];
        financialID = financialID2.filter((item: any) => item.limit !== undefined);

        const provinceName = provinces.filter((item: any) => {
          if (item.provinceId === values.province) {
            return item
          }
          return
        });

        const newData = {
          title: values.title,
          province: provinceName[0]?.name,
          state: values.district,
          taxAdministration: values.taxAdministration,
          taxId: values.taxId,
          address: values.address,
          type: values.type,
          isIntegrated: checkIntegration,
          autoMarkInvoicesAsPaidEnabled: check,
          financialInstitutionLimits: financialID
        }

        const response = await createBuyerIdentifyApi(newData);
        if(response) {
          notification.success({
            message: `Başarıyla kayıt oluşturulmuştur.`,
          });   
          form.resetFields();
          setCheck(false);
          setCheckIntegration(false);
          setState([]);
          } 
          setLoading(false)

      } catch (e: any) {
        console.log('e',e)
        setLoading(false);
        if (e.status === 409) {
          notification.error({
            message: `Aynı VKN ile kayıt bulunmaktadır (${values.taxId}) `,
          });
        }
        if (e.status === 400 && e.response.Type.includes('FinancialInstitutionLimitsCannotBeNullException')) {
          notification.error({
            message: `En az bir finans kurumu için limit bilgisi tanımlamalısınız. `,
          });
        }
      }
    }
  };



const getDistrictsTaxId = async (dataId:any) => {
  form.resetFields(['taxAdministration']);
  form.resetFields(['invoiceDistrict']);
  try {
    const response: any = await getDistrictsApi(dataId);
    if (response) {
      setDistrictList(response);
    }
  } catch (error: any) {
    console.log(error);
  }
  try {
    const response: any = await getTaxAdministartions(dataId);
    if (response) {
      setTaxAdministartion(response);
    }
  } catch (error: any) {
    console.log(error);
  }
}


const getCorporationsList =  async () => {
  try {
    const response: any = await getCorporationsListApi();
    if (response) {
      setFinancial(response.financialInstitutions)
      setState(
        response.financialInstitutions.map((item:any) => ({
          id: item.id,
          isChecked: false,
          limit: "",
        }))
        )
    }
  } catch (error: any) {
    console.log(error);
  }
}

  useEffect(() => {
    getCorporationsList();
       // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[])

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title"> Alıcı Tanımlama</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            name="user-update"
            className="default-form"
            onFinish={onFinish}
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="vertical"
            initialValues={{
              size: 'large',
            }}
            size={'large'}
            style={{
              width: '100%',
              display: 'flex'
            }}
          >
            <div className="form-col-box" style={{ paddingTop: '10px' }}>

              <Form.Item
                label="Ünvan:"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen ünvan giriniz.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Alıcı Türünü Seç:"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen alıcı türünü  seçiniz.',
                  },
                ]}
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                >
                  <Select.Option key='1' value='TEDARIKCI_ALICISI' >Tedarikçi Alıcısı</Select.Option>
                  <Select.Option key='2' value='CIFTCI_ALICISI' >Çiftçi Alıcısı</Select.Option>
                  <Select.Option key='3' value='CIFTCI_VE_TEDARIKCI_ALICISI' >Tedarikçi ve Çiftçi Alıcısı</Select.Option>
                </Select>
              </Form.Item>

              <div className="form-col-box">
                <h4 style={{ marginBottom: '20px', textDecoration: 'underline' }}>Fatura Adresi</h4>
                <Form.Item
                  label="İl:"
                  name="province"
                  rules={[
                    {
                      required: true,
                      message: 'Lütfen il giriniz.',
                    },
                  ]}
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                    onChange={(e: any) => getDistrictsTaxId(e)}
                  >
                    {
                      provinces && provinces.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.provinceId}>{item.name}</Select.Option>
                        )
                      })
                    }

                  </Select>
                </Form.Item>
                <Form.Item
                  label="İlçe:"
                  name="district"
                  rules={[
                    {
                      required: true,
                      message: 'Lütfen ilçe giriniz.',
                    },
                  ]}
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                  >
                    {
                      districtList && districtList.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item}>{item}</Select.Option>
                        )
                      })
                    }

                  </Select>
                </Form.Item>
                <Form.Item
                  label="Vergi Dairesi:"
                  name="taxAdministration"
                  rules={[
                    {
                      required: true,
                      message: 'Lütfen vergi dairesi seçiniz.',
                    },
                  ]}
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                  >
                    {
                      taxAdministartion && taxAdministartion.length > 0 ? taxAdministartion.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                        )
                      }) : ''
                    }

                  </Select>
                </Form.Item>
                <Form.Item
                  label="Vergi Kimlik Numarası:"
                  name="taxId"
                  rules={[
                    {
                      validator: (_, value) => validateVkn(value)
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Adres"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: 'Lütfen adres giriniz.',
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                  />
                </Form.Item>

                <div>
                  <Form.Item name="autoMarkInvoicesAsPaidEnabled" style={{ marginBottom: '0' }} >
                    <Checkbox checked={check} onChange={onChange}>Otomatik Fatura Ödendi Olarak İşaretle</Checkbox>
                  </Form.Item>
                  <Form.Item name="isIntegrated" >
                    <Checkbox checked={checkIntegration} onChange={onChangeIntegration}>Entegre Alıcısı</Checkbox>
                  </Form.Item>
                </div>

                <h4 style={{ marginBottom: '30px', marginTop: '30px', textDecoration: 'underline' }}>Finans Kurumları</h4>
                <div className="detail-column" style={{ border: 'none' }}>
                  <ul style={{ paddingLeft: '0' }} className="dev-label">
                    <Spin spinning={loading}>
                    {
                      financial && financial.map((item: any, index: any) => {
                        const currentItem = state.find((s: any) => s.id === item.id);
                        return (
                          <li className="fins-list-div" key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Item name='checkbox' >
                              <Checkbox
                                name={item.id}
                                checked={currentItem?.isChecked}
                                onChange={(e) =>
                                  handleCheckboxChange(item.id, e.target.checked)
                                }
                              /> 
                              {/* <span>{item.name}</span> */}
                             <span>{item.name.length > 23 ? `${item.name.slice(0, 23)}...` : item.name}</span> 
                            </Form.Item>

                            <Form.Item
                              name={`${index}limit`}
                              className="dev-input"
                              style={{ paddingLeft: '5px' }}
                            >
                              <Input
                                name={`${index}limit`}
                                key={`${index}id`}
                                value={currentItem?.limit}
                                onChange={(e) => handleInputChange(item.id, e.target.value)}
                              />
                            </Form.Item>
                            {
                              item.taxId === "0150015264" ?
                                (
                                  <Form.Item
                                    name={`${index}company`}
                                    className="dev-input"
                                    style={{ paddingLeft: '10px' }}
                                  >
                                    <Input
                                      name={`${index}company`}
                                      key={`${index}id`}
                                      placeholder={`${item.name.slice(0, item.name.indexOf(" "))} CompanyCode`}
                                    />
                                  </Form.Item>
                                ) : ''
                            }
                          </li>
                        )
                      })
                    }
                     </Spin>
                  </ul>
                </div>
                <Form.Item style={{ marginTop: '20px' }}>
                  <Button type="primary" htmlType="submit" loading={loading}>Alıcı Kaydet</Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
     
    </main>
  );
};


export default Identification;
