
import React from "react";
import { Button,  Popover,  Space, Typography, Drawer,  Dropdown  } from "antd";
import { Link, useLocation } from "react-router-dom";
import { urls } from "../router/auth";
import Logo from "../assets/logo.png";
import Icon from "../assets/icon.svg";
import { LocalStorageService } from "../helpers/LocalStorage";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {setNavShow} from "../reducers/userSlice";
import MobilMenu from "./MobilMenu";
import type { MenuProps } from 'antd';
import {logoutApi} from '../services/userAuth'

const { Text } = Typography;

const AccountMenu = () => {

  const onClickLogout = async () => {

    try{
      await logoutApi();
      LocalStorageService.removeAuthToken();
      LocalStorageService.removeAuthName();
      LocalStorageService.removeActiveRow();
      LocalStorageService.removeProvince();
      LocalStorageService.removeUserRole();

       }catch(e:any){
         console.log(e)
       }
  };

  return (
      <>
          <Link to={urls.login}>
              <div onClick={onClickLogout}>
                  <Text className="m-0" style={{ color: "red" }}>
                      Çıkış Yap
                  </Text>
              </div>
          </Link>
      </>
  );
};

const Header = () => {
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const {navShow} = useSelector((state:any) => state.user)

  const email = LocalStorageService.getName();
  const userLetters = email?.length && email[0].charAt(0).toUpperCase() ;

  const showDrawer = () => {
    dispatch(setNavShow(true));
  };
  const onClose = () => {
    dispatch(setNavShow(false));
  };
  const onClickLogout = async () => {
    try{
      await logoutApi();
      LocalStorageService.removeAuthToken();
      LocalStorageService.removeAuthName();
      LocalStorageService.removeActiveRow();
      LocalStorageService.removeProvince();
      LocalStorageService.removeUserRole();

       }catch(e:any){
         console.log(e)
       }
  };
  const tedarikci: MenuProps['items'] = 
  [
    {
      key: '1',
      label: (
        <Link to={urls.accountTransactions}>
          Hesap İşlemleri
        </Link>      
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.transactionsHistory}>
           İşlem Geçmişi
        </Link> 
      ),
    },
    {
      key: '3',
      label: (
        <Link to={urls.supplierRiskMovements}>
           Tedarikçi Risk Hareketleri
        </Link> 
      ),
    },
    {
      key: '4',
      label: (
        <Link to={urls.applicantSuppliers}>
           Aday Tedarikçiler
        </Link> 
      ),
    },
  ]

  const ciftci: MenuProps['items'] = 
  [
    {
      key: '1',
      label: (
        <Link to={urls.farmersAccountTransactions}>
          Hesap İşlemleri
        </Link>      
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.farmersTransactionsHistory}>
           İşlem Geçmişi
        </Link> 
      ),
    },
    {
      key: '3',
      label: (
        <Link to={urls.farmersRiskMovements}>
           Çiftçi Risk Hareketleri
        </Link> 
      ),
    },
    {
      key: '4',
      label: (
        <Link to={urls.applicantFarmers}>
           Aday Çiftçiler
        </Link> 
      ),
    },
  ]

  const finans: MenuProps['items'] = 
  LocalStorageService.getUserRole() === 'SUPERVISOR' ?  
  [ 
    {
      key: '1',
      label: (
          <Link to={urls.corporationSave}>
          Hesap Açılış İşlemleri
        </Link>       
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.corporationUserSave} >
          Kullanıcı Tanımlama
        </Link>   
      ),
    },
    {
      key: '3',
      label: (
        <Link to={urls.corporationsList}>
        Kurumlar Listesi
      </Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={urls.riskLimitInformation}>
          Risk/Limit Bilgileri
       </Link>         
      ),
    },
  ]:
  [ 
    
    {
      key: '3',
      label: (
        <Link to={urls.corporationsList}>
        Kurumlar Listesi
      </Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link to={urls.riskLimitInformation}>
          Risk/Limit Bilgileri
       </Link>         
      ),
    },
  ]

  const alici: MenuProps['items'] =
  LocalStorageService.getUserRole() === 'SUPERVISOR' ?  
  [
    {
      key: '1',
      label: (
        <Link to={urls.identification}>
          Alıcı Tanımlama
        </Link>     
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.userIdentificationSave}>
        Alıcı Kullanıcısı Tanımlama
      </Link> 
      ),
    },
    {
      key: '3',
      label: (
        <Link to={urls.buyersList}>
          Alıcılar Listesi
        </Link>  
      ),
    },
    {
      key: '4',
      label: (
        <Link to={urls.buyerUploadInvoice}>
        Alıcı Adına Fatura Yükleme
      </Link>  
      ),
    },
    {
      key: '11',
      label: (
        <Link to={urls.buyerUploadeMustahsil}>
        Alıcı Adına E-Müstahsil Yükleme
      </Link>  
      ),
    },
    {
      key: '5',
      label: (
        <Link to={urls.buyerUploadSupplier}>
        Alıcı Adına Tedarikçi Yükleme
      </Link>  
      ),
    },
    {
      key: '6',
      label: (
        <Link to={urls.invoicesstatus}>
        Faturaların Durumu
      </Link>  
      ),
    },
    {
      key: '7',
      label: (
        <Link to={urls.invoiceMaturityUpdate}>
        Fatura Vadesi Güncelleme
      </Link>  
      ),
    },
    {
      key: '8',
      label: (
        <Link to={urls.dummyDataDelete}>
        Alıcı/Tedarikçi Sil
      </Link>  
      ),
    },
  
  ]:
  [ 
    {
      key: '3',
      label: (
        <Link to={urls.buyersList}>
          Alıcılar Listesi
        </Link>  
      ),
    },
    {
      key: '6',
      label: (
        <Link to={urls.invoicesstatus}>
        Faturaların Durumu
      </Link>  
      ),
    },
    {
      key: '7',
      label: (
        <Link to={urls.invoiceMaturityUpdate}>
        Fatura Vadesi Güncelleme
      </Link>  
      ),
    },
  ];

  const admin: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={urls.newUser}>
          Kullanıcı Oluştur
        </Link>      
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.userTracing}>
            Kullanici İzleme
        </Link> 
      ),
    },

  ];

  const yonetici: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={urls.templateCreate}>
            Şablon Oluştur
        </Link> 
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.templateEdit}>
            Şablon Düzenle / Sil
        </Link> 
      ),
    },
    {
      key: '3',
      label: (
        <Link to={urls.testMailSend}>
            Test Maili Gönderme
        </Link> 
      ),
    },
    {
      key: '4',
      label: (
        <Link to={urls.dataTransfer}>
            Veri Aktarma
        </Link> 
      ),
    },

  ];


 

  return (
   <header>
    <div className="logo">
      <Link to={urls.homePage}>
        <img className="bigLogo" src={Logo} alt="Depar.io Logo" />
      </Link>
    </div>
    <div className="menu">
      <nav>
        <Space>
          {/* <Link key={`header-link-${urls.homePage}`} to={urls.homePage}>
              <Button
                className={`header-btn fatura-yukle ${path === urls.homePage && " active-line"}`}
                type="default"
              >
                Anasayfa
              </Button>
            </Link> */}
          
          <Dropdown menu={{ items:admin }} placement="bottomLeft" arrow  className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
            <Button className={`header-btn temlik-fatura ${path === urls.newUser ? 'active-line' : path === urls.userTracing ? 'active-line' : ''}`}  >
              <span>Admin</span>
            </Button>  
          </Dropdown>

          <Dropdown menu={{ items:tedarikci }} placement="bottomLeft" arrow>
            <Button className={`header-btn temlik-fatura ${path === urls.accountTransactions ? 'active-line' : 
            path === urls.transactionsHistory ? 'active-line' : path === urls.supplierRiskMovements ? 'active-line' : path === urls.applicantSuppliers ? 'active-line' : ''}`}  >
              <span>Tedarikçi</span>
            </Button>  
          </Dropdown>

          <Dropdown menu={{ items:ciftci }} placement="bottomLeft" arrow>
            <Button className={`header-btn temlik-fatura ${path === urls.farmersAccountTransactions ? 'active-line' : 
            path === urls.farmersTransactionsHistory ? 'active-line' : path === urls.farmersRiskMovements ? 'active-line' : path === urls.applicantFarmers ? 'active-line' : ''}`}  >
              <span>Çiftçi</span>
            </Button>  
          </Dropdown>

          <Dropdown menu={{ items:finans }} placement="bottomLeft" arrow >
          <Button 
            className={`header-btn temlik-fatura 
              ${path === urls.corporationSave ? 'active-line' 
              : path === urls.corporationUserSave ? 'active-line' 
              :  path === urls.corporationsList ? 'active-line' 
              :  path === urls.riskLimitInformation ? 'active-line' : ''
              } `}  >
                <span>Finansal Kurum</span>
          </Button>   
          </Dropdown>

          <Dropdown menu={{ items:alici }} placement="bottomLeft" arrow>
          <Button 
            className={`header-btn temlik-fatura 
              ${path === urls.identification ? 'active-line' 
              : path === urls.userIdentificationSave ? 'active-line' 
              :  path === urls.buyersList ? 'active-line' 
              :  path === urls.buyerUploadInvoice ? 'active-line' 
              :  path === urls.buyerUploadeMustahsil ? 'active-line' 
              :  path === urls.invoiceMaturityUpdate ? 'active-line' 
              :  path === urls.buyerUploadSupplier ? 'active-line' : ''
               }`} 
          >
            <span>Alıcı</span>
          </Button> 
          </Dropdown>

          <Dropdown menu={{ items:yonetici }} placement="bottomLeft" arrow>
          <Button 
            className={`header-btn temlik-fatura 
              ${path === urls.templateWatch ? 'active-line' 
              // : path === urls.templateCreate ? 'active-line' 
              :  path === urls.templateEdit ? 'active-line' : ''
               }`} 
          >
            <span>Bildirim Yönetimi</span>
          </Button> 
          </Dropdown>
         
        </Space>
      </nav>
      <div className={`menu-toggle ${navShow ? 'open': ''}`}  onClick={showDrawer} >
        <div className="hamburger"></div>
      </div>
      <Drawer
      title={
        <Link to={urls.homePage} >
          <img className="bigLogo" src={Logo} alt="Depar.io Logo" />
        </Link>
        }
        placement="left"
        width={350}
        onClose={onClose}
        open={navShow}
        >
        <div className="mobil-userName">
          <div className="left">
            <UserOutlined />
            <p style={{fontWeight:'400', paddingTop:'0', marginTop:'0'}}>{LocalStorageService.getName()}</p>
          </div>
          <div style={{textAlign:'right'}}>
            <Link to={urls.login}>
                <div onClick={onClickLogout}>
                    <Text className="m-0" style={{ color: "red" }}>
                        Çıkış
                    </Text>
                </div>
            </Link>
          </div>
        </div>
        <div className="mobil-menu">
          <MobilMenu/>    
        </div>
    </Drawer>
    </div>
    <div className="custom-menu">
      <Popover
            className="cursor-pointer"
            content={() => <AccountMenu />}
            trigger="click"
            placement="bottomRight"
            >
            <div style={{ display:'flex', alignItems:'center', gap:'10px'}}> 
              <div className="username-box">
                {userLetters} 
              </div>
              <div className="my-account-box">
              Hesabım
              </div> 
              <img src={Icon} className="my-account-icon" alt="icon"/>
            </div>
        </Popover>
    </div>
   </header>
  );
}

export default Header;
