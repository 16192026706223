import React, { useEffect, useState , useRef} from "react";
import { Row, Col, Table, Button, Modal, Input, Space} from "antd";
import {  SearchOutlined } from "@ant-design/icons";
import moment from 'moment';
import { getAgriculturistDatas } from "../../services/agroApi";
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from "../../helpers/LocalStorage";
import type { InputRef } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import type { ColumnType } from 'antd/es/table';

function AccountTransactions() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [agriculturist, setAgriculturist] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [deleteId, setDeleteId] = useState({
    id:'',
    name:''
  });
  const [filters, setFilters] = useState<any>({
    taxId: '',
    name: ''
  });
  const searchInput = useRef<InputRef>(null);
  
  const getAgriculturistData = async () => {
    setLoading(true)
    try{
      const {
        taxId,
        name,
      } = filters;     
      const filterStr = `${taxId ? `&searchAgriculturistTaxNumber=${taxId}` : ''}
          ${name ? `&searchAgriculturistFullName=${name}` : ''}
       `
       const response: any = await getAgriculturistDatas(filterStr.trim().replaceAll(/\n/g,'').replace(/\s{2,}/g,''),page,psize);
       if(response){
         setLoading(false)
         setAgriculturist(response?.agriculturists?.data);
         setTotalDataCount(response?.agriculturists?.totalDataCount)
         setLoading(false) 
       }
    } catch(e:any){
      setLoading(false)
    }
  }

  // const deleteAgriculturist = async (id:any, title:any) => {
  //   if(id){
  //     try{
  //       await deleteAgriculturistId(id);
  //       // getSupplierData();
  //     }catch(e:any){
  //       if(e.status === 403 && e.data.Type.includes('SupplierHasRiskException')){
  //         notification.warning({
  //             message: `Riski bulunan tedarikçiyi silemezsiniz.`,
  //         });
  //       }
  //     }
  //   }
  // }

  // const showModal = (id:any, title: any) => {
  //   setDeleteId({id: id, name: title})
  //   setOpen(true);
  // };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
    // deleteAgriculturist(deleteId.id, deleteId.title);
  };
  const handleCancel = () => {
    setOpen(false);
    setDeleteId({id:'', name:''})
  };

  interface DataType {
    key: string;
    taxId: string;
    name: string;
  }

  type DataIndex = keyof DataType;

  const handleSearchTaxtID = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      taxId: selectedKeys[0],
    })
  };

  const handleResetTaxtID = (clearFilters: () => void, setSelectedKeys: (keys: string[]) => void, confirm: () => void) => {
    clearFilters();
    setFilters({
      ...filters,
      taxId : '',
    })
    setSelectedKeys([]);
    confirm(); 
  };

  const handleSearchTitle = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      name: selectedKeys[0],
    })
  };

  const handleResetTitle = (clearFilters: () => void, setSelectedKeys: (keys: string[]) => void, confirm: () => void) => {
    clearFilters();
    setFilters({
      ...filters,
      name : '',
    })
    setSelectedKeys([]);
    confirm(); 
  };

const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()} className="account-transactions-input">
        <Input
          ref={searchInput}
          placeholder='Çiftçi VKN/TCKN'
          onInput = {(e:any) =>{
            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,11)
          }}
          type="number"
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchTaxtID(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchTaxtID(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleResetTaxtID(clearFilters, setSelectedKeys, confirm)}
            size="small"
            style={{ width: 90 }}
            className="searc-btn-table"
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    onFilterDropdownOpenChange: (visible:any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select());
      }
    },
  });

const getSearchTitleProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={searchInput}
        placeholder='Ad Soyad'
        onInput = {(e:any) =>{
          e.target.value = (e.target.value).toString()
        }}
        type="string"
        // value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearchTitle(selectedKeys as string[], confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearchTitle(selectedKeys as string[], confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Ara
        </Button>
        <Button
          onClick={() => clearFilters && handleResetTitle(clearFilters, setSelectedKeys, confirm)}
          size="small"
          style={{ width: 90 }}
          className="searc-btn-table"
        >
          Sıfırla
        </Button>
      </Space>
    </div>
  ),
  onFilterDropdownOpenChange: (visible:any) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select());
    }
  },
});

const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
};

useEffect(() => {
  getAgriculturistData();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
},[page,psize, filters])

const columns : any = [
    {
      title: 'Çiftçi VKN/TCKN',
      dataIndex: 'taxId',
      key: "taxId",
      fixed: 'left',
      width: 200,
      ...getColumnSearchProps('taxId'),
      sorter: {
        compare: (a:any, b:any) => a.taxId - b.taxId,
        multiple: 2,
      },
    },
    {
      title: 'Ad Soyad',
      dataIndex: 'name',
      key: "name",
      width: 180,
      render: (text:any, record:any) => `${record.name} ${record.surname}`,
      ...getSearchTitleProps('name'),
    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdDate',
      key: "createdDate",
      width: 180,
      render: (date:any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title:'',
      dataIndex:'detail',
      key:'detail',
      width: 120,     
      render: (text: any, row: any) =>  
        <Button 
          type="primary" 
          onClick={()  => navigate(`/farmers-account-transactions-detail/${row?.id}`)}
          > Detay
        </Button>         
  },
  {
    title:'',
    dataIndex:'update',
    key:'update',  
    // width: 120,      
    render: (text: any, row: any) =>  
      <Button 
        type="primary" 
        className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
        onClick={()  => navigate(`/farmers-account-transactions-update/${row?.id}`)}
        > Güncelle
      </Button>         
  },
  // {
  //   title:'',
  //   dataIndex:'delete',
  //   key:'delete',  
  //   width: 120,      
  //   render: (text: any, row: any) =>  
  //     <Button 
  //       style={{backgroundColor:'#F15A31', border:'#f25429'}}
  //       type="primary" 
  //       className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
  //       onClick={()  => showModal(row.id, row.title)}
  //       > Sil
  //     </Button>         
  // },
];


  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Çiftçi Hesap İşlemleri</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Table
           className="w-100 invoiceTable"
           style={{width:'auto'}}
           pagination={{
             position: ["topRight", "none" as any],
             showLessItems: true,                          
             showSizeChanger: true,
             current: page,
             pageSize:psize,
             total:totalDataCount, 
             onChange: handleShowSize,
             
           }}
          loading={loading} 
          rowKey="id"
          dataSource={agriculturist}
          columns={columns}
          scroll={{
            x: 1200,
          }}
          
        />
        </Col>
      </Row>
      <Modal
        open={open}
        className="supplier-transaction"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
      >
        <p>{deleteId?.name}, Çiftçi geri dönülemez bir şekilde silinecektir, onaylıyor musunuz?</p>
      </Modal>

    </main>
  );
};

export default AccountTransactions;
