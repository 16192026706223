import * as React from "react";
import { createContext, ReactNode, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import {useSelector} from 'react-redux';
import Login from "../pages/Login";
import { fakeAuthProvider, urls } from "./auth";
import Home from "../pages/Home";
import HomePage from "../pages/HomePage";

import NewUser from "../pages/Users/NewUser";
import UserTracing from "../pages/Users/UserTracing";
import UserDetail from "../pages/Users/UserDetail";
import UserUpdate from "../pages/Users/UserUpdate";
import CreatePassword from "../pages/Users/CreatePassword";


import AccountTransactions from "../pages/Suppliers/AccountTransactions";
import TransactionsHistory from "../pages/Suppliers/TransactionsHistory";
import TransactionsHistoryDetail from "../pages/Suppliers/TransactionsHistoryDetail";
import AccountTransactionsDetail from "../pages/Suppliers/AccountTransactionsDetail";
import AccountTransactionsUpdate from "../pages/Suppliers/AccountTransactionsUpdate";
import SupplierRiskMovements from "../pages/Suppliers/SupplierRiskMovements";
import ApplicantSuppliers from "../pages/Suppliers/ApplicantSuppliers";

import FarmersAccountTransactions from "../pages/Farmers/AccountTransactions";
import FarmersTransactionsHistory from "../pages/Farmers/TransactionsHistory";
import FarmersTransactionsHistoryDetail from "../pages/Farmers/TransactionsHistoryDetail";
import FarmersAccountTransactionsDetail from "../pages/Farmers/AccountTransactionsDetail";
import FarmersAccountTransactionsUpdate from "../pages/Farmers/AccountTransactionsUpdate";
import FarmersRiskMovements from "../pages/Farmers/FarmersRiskMovements";
import ApplicantFarmers from "../pages/Farmers/ApplicantFarmers";

import CorporationSave from "../pages/FinancialInstitution/CorporationSave";
import CorporationsList from "../pages/FinancialInstitution/CorporationsList";
import CorporationUsersList from "../pages/FinancialInstitution/CorporationUsersList"; 
import CorporationUserUpdate from "../pages/FinancialInstitution/CorporationUserUpdate"; 
import FinancialInstitutionUpdate from "../pages/FinancialInstitution/FinancialInstitutionUpdate"; 
import CorporationUserSave from "../pages/FinancialInstitution/CorporationUserSave";
import RiskLimitInformation from "../pages/FinancialInstitution/RiskLimitInformation"; 

import Identification from "../pages/Buyer/Identification";
import UserIdentificationSave from "../pages/Buyer/UserIdentificationSave";
import BuyersList from "../pages/Buyer/BuyersList";
import BuyerUploadInvoice from "../pages/Buyer/BuyerUploadInvoice";
import BuyerUploadeMustahsil from "../pages/Buyer/BuyerUploadeMustahsil";
import BuyerUploadSupplier from "../pages/Buyer/BuyerUploadSupplier";
import BuyerUsersList from "../pages/Buyer/BuyerUsersList";
import BuyerUserDetail from "../pages/Buyer/BuyerUserDetail";
import BuyerUpdate from "../pages/Buyer/BuyerUpdate";
import BuyerUserUpdate from "../pages/Buyer/BuyerUserUpdate";
import BuyerUserLimitUpdate from "../pages/Buyer/BuyerUserLimitUpdate";
import InvoiceMaturityUpdate from "../pages/Buyer/InvoiceMaturityUpdate";
import DummyDataDelete from "../pages/Buyer/DummyDataDelete";

import FarmerUserLimitUpdate from "../pages/Farmers/FarmerUserLimitUpdate";

import InvoicesStatus from "../pages/Invoices/InvoicesStatus";

import { LocalStorageService } from '../helpers/LocalStorage';
import TwoFactor from "../pages/TwoFactor";

import TemplateCreate from "../pages/Editor/TemplateCreate";
import TemplateEdit from "../pages/Editor/TemplateEdit";
import TemplateEditName from "../pages/Editor/TemplateEditName";
import TemplateEditContent from "../pages/Editor/TemplateEditContent";
import TemplateEditVariables from "../pages/Editor/TemplateEditVariables";
import TestMailSend from "../pages/Editor/TestMailSend";
import DataTransfer from "../pages/Editor/DataTransfer";

const AppRouter = () => {
 
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path={`${urls.passwordCreate}/:passwordToken/:token`}
          element={<CreatePassword />}
        />
        {/* <Route
          path={`${urls.passwordReset}/:passwordToken/:token`}
          element={<ResetPassword />}
        />
       
        <Route path={urls.forgetPassword} element={<ForgetPassword />} /> */}

        <Route  
          path={urls.twoFactor} 
            element={
              <RequireTwoFactor>
                  <TwoFactor />
              </RequireTwoFactor> 
          }/>
        
        <Route
          path='/'
          element={
            <RequireAuth>
              <Home/>
            </RequireAuth>
          }
        >
         <Route
            path={urls.homePage}
            element={
              <RequireAuth>
                <HomePage />
              </RequireAuth>
            }
          />
        <Route
            path={urls.userTracing}
            element={
              <RequireUser>
                <UserTracing />
              </RequireUser>
            }
          />
        <Route
            path={urls.newUser}
            element={
              <RequireUser>
                <NewUser />
              </RequireUser>
            }
          />
          <Route
            path="/user-detail/:id"
            element={
              <RequireUser>
                <UserDetail />
              </RequireUser>
            }
          />
            <Route
            path="/user-update/:id"
            element={
              <RequireUser>
                <UserUpdate />
              </RequireUser>
            }
          />

          <Route
            path={urls.accountTransactions}
            element={
              <RequireAuth>
                <AccountTransactions />
              </RequireAuth>
            }
          />
          <Route
            path={urls.transactionsHistory}
            element={
              <RequireAuth>
                <TransactionsHistory />
              </RequireAuth>
            }
          />
           <Route
            path="/transactions-history-detail/:id"
            element={
              <RequireAuth>
                <TransactionsHistoryDetail />
              </RequireAuth>
            }
          />
           <Route
            path="/account-transactions-detail/:id"
            element={
              <RequireAuth>
                <AccountTransactionsDetail />
              </RequireAuth>
            }
          />
           <Route
            path="/account-transactions-update/:id"
            element={
              <RequireUser>
                <AccountTransactionsUpdate />
              </RequireUser>
            }
          />
           <Route
            path={urls.supplierRiskMovements}
            element={
              <RequireAuth>
                <SupplierRiskMovements />
              </RequireAuth>
            }
          />
           <Route
            path={urls.applicantSuppliers}
            element={
              <RequireAuth>
                <ApplicantSuppliers />
              </RequireAuth>
            }
          />

          <Route
            path={urls.farmersAccountTransactions}
            element={
              <RequireAuth>
                <FarmersAccountTransactions />
              </RequireAuth>
            }
          />
          <Route
            path={urls.farmersTransactionsHistory}
            element={
              <RequireAuth>
                <FarmersTransactionsHistory />
              </RequireAuth>
            }
          />
           <Route
            path="/farmers-transactions-history-detail/:id"
            element={
              <RequireAuth>
                <FarmersTransactionsHistoryDetail />
              </RequireAuth>
            }
          />
           <Route
            path="/farmers-account-transactions-detail/:id"
            element={
              <RequireAuth>
                <FarmersAccountTransactionsDetail />
              </RequireAuth>
            }
          />
           <Route
            path="farmers-account-transactions-update/:id"
            element={
              <RequireUser>
                <FarmersAccountTransactionsUpdate />
              </RequireUser>
            }
          />
           <Route
            path={urls.farmersRiskMovements}
            element={
              <RequireAuth>
                <FarmersRiskMovements />
              </RequireAuth>
            }
          />
           <Route
            path={urls.applicantFarmers}
            element={
              <RequireAuth>
                <ApplicantFarmers />
              </RequireAuth>
            }
          />

          <Route
            path={urls.corporationSave}
            element={
              <RequireUser>
                <CorporationSave />
              </RequireUser>
            }
          />
         
          <Route
            path={urls.corporationsList}
            element={
              <RequireAuth>
                <CorporationsList />
              </RequireAuth>
            }
          />

          <Route
            path={urls.riskLimitInformation}
            element={
              <RequireAuth>
                <RiskLimitInformation />
              </RequireAuth>
            }
          />

           <Route
            path={urls.identification}
            element={
              <RequireUser>
                <Identification />
              </RequireUser>
            }
          />
           <Route
            path={urls.userIdentificationSave}
            element={
              <RequireUser>
                <UserIdentificationSave />
              </RequireUser>
            }
          />
           <Route
            path={urls.buyersList}
            element={
              <RequireAuth>
                <BuyersList />
              </RequireAuth>
            }
          />
           <Route
            path="/buyer-users-list/:id"
            element={
              <RequireAuth>
                <BuyerUsersList />
              </RequireAuth>
            }
          />
          <Route
            path="/buyer-user-detail/:id"
            element={
              <RequireAuth>
                <BuyerUserDetail />
              </RequireAuth>
            }
          />
           <Route
            path="/buyer-update/:id"
            element={
              <RequireUser>
                <BuyerUpdate />
              </RequireUser>
            }
          />
            <Route
            path="/buyer-user-update/:id"
            element={
              <RequireUser>
                <BuyerUserUpdate />
              </RequireUser>
            }
          />
          <Route
            path="/buyer-user-limit-update/:id"
            element={
              <RequireAuth>
                <BuyerUserLimitUpdate />
              </RequireAuth>
            }
          />
           <Route
            path={urls.buyerUploadInvoice}
            element={
              <RequireUser>
                <BuyerUploadInvoice />
              </RequireUser>
            }
          />
             <Route
            path={urls.buyerUploadeMustahsil}
            element={
              <RequireUser>
                <BuyerUploadeMustahsil />
              </RequireUser>
            }
          />
           <Route
            path={urls.buyerUploadSupplier}
            element={
              <RequireUser>
                <BuyerUploadSupplier />
              </RequireUser>
            }
          />

        <Route
            path="/farmer-user-limit-update/:id"
            element={
              <RequireAuth>
                <FarmerUserLimitUpdate/>
              </RequireAuth>
            }
          />

          <Route
            path={urls.corporationUserSave}
            element={
              <RequireUser>
                <CorporationUserSave />
              </RequireUser>
            }
          />
           <Route
            path="/corporations-users-list/:id"
            element={
              <RequireAuth>
                <CorporationUsersList />
              </RequireAuth>
            }
          />
           <Route
            path="/corporations-user-update/:id"
            element={
              <RequireUser>
                <CorporationUserUpdate />
              </RequireUser>
            }
          />
          <Route
            path="/financial-institution-user/:id"
            element={
              <RequireUser>
                <FinancialInstitutionUpdate />
              </RequireUser>
            }
          />
          <Route
            path={urls.invoicesstatus}
            element={
              <RequireAuth>
                <InvoicesStatus/>
              </RequireAuth>
            }
          />

           <Route
            path={urls.templateCreate}
            element={
              <RequireAuth>
                <TemplateCreate/>
              </RequireAuth>
            }
          />
           <Route
            path={urls.templateEdit}
            element={
              <RequireAuth>
                <TemplateEdit/>
              </RequireAuth>
            }
          />
          <Route
            path={urls.testMailSend}
            element={
              <RequireAuth>
                <TestMailSend/>
              </RequireAuth>
            }
          />
           <Route
            path={urls.dataTransfer}
            element={
              <RequireAuth>
                <DataTransfer/>
              </RequireAuth>
            }
          />
           <Route
            path="/template-edit-name/:id"
            element={
              <RequireAuth>
                <TemplateEditName/>
              </RequireAuth>
            }
          />
          <Route
            path="/template-edit-content/:id"
            element={
              <RequireAuth>
                <TemplateEditContent/>
              </RequireAuth>
            }
          />
           <Route
            path="/template-edit-variables/:id"
            element={
              <RequireAuth>
                <TemplateEditVariables/>
              </RequireAuth>
            }
          />
          <Route
            path={urls.invoiceMaturityUpdate}
            element={
              <RequireAuth>
                <InvoiceMaturityUpdate/>
              </RequireAuth>
            }
          />
           <Route
            path={urls.dummyDataDelete}
            element={
              <RequireAuth>
                <DummyDataDelete/>
              </RequireAuth>
            }
          />
          

          {/* <Route
            path="/details/:id"
            element={
              <RequireAuth>
                <ApprovalReqDetail />
              </RequireAuth>
            }
          /> */}
        </Route>
      </Routes>
    </AuthProvider>
  );
};

interface AuthContextType {
  token: any;
  signin: (token: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<any>(null);

  useEffect(() => {
    const token = LocalStorageService.getAuthToken();
    token && LocalStorageService.setAuthToken(token);
  }, []);

  const signin = (newToken: string, callback: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setToken(newToken);
      callback();
    });
  };

  const signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setToken(null);
      callback();
    });
  };

  const value = { token, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const token = LocalStorageService.getAuthToken();

  if (!auth.token && !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (location.pathname === "/") {
    return (
      // <Navigate to={urls.uploadInvoice} state={{ from: location }} replace />
      <Navigate to={urls.homePage} state={{ from: location }} replace />
    );
  }

  return children;
}

function RequireTwoFactor({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const token = LocalStorageService.getAuthToken();
  const {email} = useSelector((state: any) => state.user);

  if (!auth.token && !token &&!email) {
    return <Navigate to="/login" state={{ from: location }} replace  />;
  } 
 if (token && !email) {
    return <Navigate to={urls.homePage}  state={{ from: location }} replace  />;
  } 
 if (location.pathname === "/" ) {
    return (
      // <Navigate to={urls.uploadInvoice} state={{ from: location }} replace />
      <Navigate to={urls.homePage} state={{ from: location }} replace />
    );
  }
  return children;
}

function RequireUser({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const token = LocalStorageService.getAuthToken();
  const userRole = LocalStorageService.getUserRole() === 'SUPERVISOR';

  if (!auth.token && !token && !userRole) {
    return <Navigate to="/login" state={{ from: location }} replace  />;
  } 
 if (token && !userRole) {
    return <Navigate to={urls.homePage}  state={{ from: location }} replace  />;
  } 
 if (location.pathname === "/" ) {
    return (
      // <Navigate to={urls.uploadInvoice} state={{ from: location }} replace />
      <Navigate to={urls.homePage} state={{ from: location }} replace />
    );
  }
  return children;
}

export default AppRouter;
