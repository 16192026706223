import React, { useState, useEffect } from "react";
import { Row,  Col, Table , Tabs , Button} from "antd";
import moment from 'moment';
import { getTxnHistoryByTaxIdApi } from "../../services/agroApi";
import { discountApplicationCancel } from "../../services/financeApi";
import { convertFloatDotSeperated, convertCurrencyTl } from "../../helpers/common";
import { useLocation, useNavigate } from "react-router-dom";
import {applicationStatusMapByValue} from "../../helpers/common";
import { LoadPdfTemlik, LoadPdfTemlikDyb , LoadPdfTemlikDenizFactoring} from '../../helpers/file';

function TransactionsHistoryDetail() {
  const location = useLocation();
  let navigate = useNavigate();
  const discountsId = location.pathname.split('/')[2];
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [detail, setDetail] = useState<any>();
  const [approvedInvoices, setApprovedInvoices] = useState([]);
  const [unapprovedInvoices, setUnapprovedInvoices] = useState([]);
  const [invoiceStatus, setInvoiceStatus] = useState<any>([]);
  const { TabPane } = Tabs;

  const statusOutput = (value:any) =>  {
    if(value === 'SECILDI'){
        return <h4 style={{color: '#62b61f'}}>Onaylandı</h4>
    }
    if(value === 'SECILMEDI'){
        return <h4 style={{color: '#ff0200'}}>Onaylanmadı</h4>
    } 
    if(value === 'ON_ODEME_YAPILDI'){
      return <h4 style={{color: '#40a9ff'}}>Ön Ödeme Yapıldı</h4>
    }
    if(value === 'ODENDI'){
        return <h4 style={{color: '#31960e'}}>Ödendi</h4>
    }   
    if(value === 'SECIM_YAPILMADI'){
      return <h4 style={{color: '#c90e0e'}}>Seçim Yapılmadı</h4>
  } 
}

  const columns: any = [
    {
      title: "Fatura No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      fixed: 'left',
    },
    {
      title: "Fatura Tarihi",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (date:any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: "Fatura Vadesi",
      dataIndex: "invoiceTerm",
      key: "invoiceTerm",
      render: (date:any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      render: convertFloatDotSeperated,
    },
    {
      title: "Faiz ve Komisyon Toplamı",
      dataIndex: "sumInterestCommission", 
      key: "sumInterestCommission",
      render: (item:any) => `${item} %`,
    },
    {
      title: "Onay Durumu",
      dataIndex: "status",
      key: "status",
      render: (value:any) => statusOutput(value)
    }
    
  ];

  const getSuppleirProcessDetail  = async () => {  
    try {
      setLoading(true);
      const response: any = await getTxnHistoryByTaxIdApi(discountsId);
      if (response) {
        setLoading(false);
        setDetail(response);
        setApprovedInvoices(
          response && response.invoices.filter((item:any) => item.status === 'YUKLENDI' || item.status === 'SECILDI' || item.status === 'ODEME_BEKLENIYOR' || item.status === 'ON_ODEME_YAPILDI' || item.status === 'ODENDI' || item.status === 'ODENMEDI' 
        ));
        setUnapprovedInvoices(
          response && response.invoices.filter((item:any) => item.status === 'SECIM_YAPILMADI' || item.status === 'SECILMEDI'
        ));
        setInvoiceStatus(
          response && response.invoices.filter((item:any) => item.status === 'ODENDI' || item.status === 'ON_ODEME_YAPILDI' || item.status === 'SURESI_DOLDU' || item.status === 'IPTAL_EDILDI' 
        ));
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }

  const handleTemlikDownload = async () => {
    setDownloadLoading(true)
    if(detail.financialInstitutionTaxId === '2910141668'){
      const res = await LoadPdfTemlikDenizFactoring(detail);
      if(res){
        setDownloadLoading(false)
       }

    } else if(detail.financialInstitutionTaxId === '2651554884'){
      const res = await LoadPdfTemlikDyb(detail);
      if(res){
       setDownloadLoading(false)
      }
    } else if(detail.financialInstitutionTaxId !== '2910141668' || detail.financialInstitutionTaxId !== '2651554884' ){
      const res = await LoadPdfTemlik(detail);
      if(res){
       setDownloadLoading(false)
      }
    }
};

const changeCancel = async  () => {    
  if(detail){
    await  discountApplicationCancel(detail?.number); 
    navigate("/transactions-history");
  } 
 };
 
  useEffect(() => {
    getSuppleirProcessDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [])

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">İşlem Geçmişi Detay</h3>
        </Col>
      </Row>
      <Row> 
        <Col md={7} xs={24} style={{paddingRight:'40px', marginTop:'20px'}}>
          <div className="left-title"> 
          { applicationStatusMapByValue[detail?.status] && 
           <h3 style={{color:applicationStatusMapByValue[detail?.status].color}}>{applicationStatusMapByValue[detail?.status].text}</h3> 
          }
          </div>
          <ul className="left-item">
          {
              detail?.status === 'TEKLIFI_ONAYLADIM'  &&  (
                <li>
                <Button 
                  type="primary" 
                  style={{maxWidth:'180px',minWidth: '120px', margin:'0 auto'}}
                  onClick={handleTemlikDownload}
                  loading={downloadLoading}
                > Boş Temlikname İndir</Button>
              </li>
              )
            }
            {
              invoiceStatus?.length === 0 ? (
                <Row className="item-row" style={{ justifyContent: 'center', marginBottom:'20px' }}>
                  <Button
                    onClick={changeCancel}
                    type="primary"
                    style={{ marginBottom: '10px', backgroundColor: '#f00', minWidth: '120px', maxWidth:'180px',border: 'none' }}
                  >Başvuruyu İptal Et</Button>
                </Row>
              ): ''
            }
         
            <li>
              <span>Başvuru No:</span>
              <span>{detail?.number}</span>
            </li>
            <li>
              <span>İşlem Tarihi:</span>
              <span>{moment(detail?.date).format('DD-MM-YYYY')}</span>
            </li>
            <li>
              <span>Çiftçi VKN/TCKN:</span>
              <span>{detail?.agriculturistTaxNumber}</span>
            </li>
            <li>
              <span>Alıcı VKN/TCKN:</span>
              <span>{detail?.buyerTaxNumber}</span>
            </li>
            <li>
              <span>Finans Kurumu:</span>
              <span>{detail?.financialInstitutionName}</span>
            </li>
            <li>
              <span>Toplam Fatura Tutarı:</span>
              <span>{convertCurrencyTl(detail?.invoicesTotal)}</span>
            </li>
            <li>
              <span>Toplam Fatura Adedi:</span>
              <span>{detail?.invoicesCount}</span>
            </li>
            <li>
              <span>Ortalama Vade:</span>
              <span>{detail?.averageMaturity}</span>
            </li>
            <li>
              <span>Teklif Tutarı:</span>
              <span>{detail?.offer ? convertCurrencyTl(detail?.offer): '-'}</span>
            </li>
          </ul>
        </Col>
        <Col  md={17} xs={24}>
          {
          detail &&  detail.status === 'TEKLIF_BEKLIYOR' || detail && detail.status === 'ISLEME_ALINDI'  || detail &&  detail.status === 'SURESI_DOLDU'? 
            (
              <Table
                    className="w-100 invoiceTable"
                    style={{marginTop:'20px'}}
                    loading={loading} 
                    dataSource={detail.invoices}
                    columns={columns}
                    scroll={{
                      x: 800,
                    }}
                  />
            ) 
            : 
            (
              <Tabs  type="card" className="transaction-history-detail-tabs">
                <TabPane tab="Onaylanan Faturalar" key="1">
                  {
                    approvedInvoices.length <= 0 ? 
                    <p style={{padding:'20px', fontSize:'15px', fontWeight: '500'}}>
                      Onaylanan faturanız bulunmamaktadır.
                  </p>
                    : (
                      <Table
                        className="w-100 invoiceTable"
                        style={{marginTop:'30px'}}
                        loading={loading} 
                        dataSource={approvedInvoices}
                        columns={columns}
                        scroll={{
                          x: 800,
                        }}
                      />
                    )
                  }
                  
                </TabPane>
                <TabPane tab="Onaylanmamış Faturalar" key="2">
                {
                    unapprovedInvoices.length <= 0 ? 
                    <p style={{padding:'20px', fontSize:'15px', fontWeight: '500'}}>
                      Onaylanmamış faturanız bulunmamaktadır.
                    </p> 
                    : (
                      <Table
                        className="w-100 invoiceTable"
                        style={{marginTop:'30px'}}
                        loading={loading} 
                        dataSource={unapprovedInvoices}
                        columns={columns}
                        scroll={{
                          x: 800,
                        }}
                      />
                    )
                  }
                </TabPane>
            </Tabs>
            )
          }
   
        </Col>
      </Row>
    
    </main>
  );
};

export default TransactionsHistoryDetail;
