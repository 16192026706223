import React from "react";
import {  Menu} from "antd";
import { Link } from "react-router-dom";
import { urls } from "../router/auth";
import {setNavShow} from "../reducers/userSlice";
import { useDispatch } from "react-redux";
import { LocalStorageService } from "../helpers/LocalStorage";

const { SubMenu } = Menu;

function MobilMenu() {
  const dispatch = useDispatch();

  const onOpenChange = (keys:any) => {
    dispatch(setNavShow(false));
  };

 
  return (
    <>
        <Menu onClick={onOpenChange} style={{ width:'100%' }} mode="inline" >
            <Menu.Item key="m0">
                <Link to={urls.homePage} style={{fontWeight:'600'}}>
                    Anasayfa
                </Link>   
            </Menu.Item>
            <SubMenu
                key="sub5"
                className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
                title={
                    <span>
                        <span>Admin</span>
                    </span>
                }
                >
                <Menu.Item key="m0">
                    <Link to={urls.newUser}>
                        Kullanıcı Oluştur
                    </Link>   
                </Menu.Item>
                <Menu.Item key="m1">
                    <Link to={urls.userTracing}>
                        Kullanici İzleme
                    </Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu
            key="sub1"
            title={
                <span>
                    <span>Tedarikçi</span>
                </span>
            }
            >
                <Menu.Item key="m0">
                    <Link to={urls.accountTransactions}>
                        Hesap İşlemleri
                    </Link>   
                </Menu.Item>
                <Menu.Item key="m1">
                    <Link to={urls.transactionsHistory}>
                        İşlem Geçmişi
                    </Link>
                </Menu.Item>
                <Menu.Item key="m2">
                    <Link to={urls.supplierRiskMovements}>
                        Tedarikçi Risk Hareketleri
                    </Link>
                </Menu.Item>
                <Menu.Item key="m3">
                    <Link to={urls.applicantSuppliers}>
                        Aday Tedarikçiler
                    </Link>
                </Menu.Item>
            </SubMenu>

            <SubMenu
            key="sub2"
            title={
                <span>
                    <span>Çiftçi</span>
                </span>
            }
            >
                <Menu.Item key="m01">
                    <Link to={urls.farmersAccountTransactions}>
                        Hesap İşlemleri
                    </Link>   
                </Menu.Item>
                <Menu.Item key="m11">
                    <Link to={urls.farmersTransactionsHistory}>
                        İşlem Geçmişi
                    </Link>
                </Menu.Item>
                <Menu.Item key="m21">
                    <Link to={urls.farmersRiskMovements}>
                     Çiftçi Risk Hareketleri
                    </Link>
                </Menu.Item>
                <Menu.Item key="m31">
                    <Link to={urls.applicantFarmers}>
                        Aday Çiftçiler
                    </Link>
                </Menu.Item>
            </SubMenu>

            <SubMenu
            key="sub3"
            title={
                <span>
                    <span>Finansal Kurum</span>
                </span>
            }
            >
                <Menu.Item key="m2" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.corporationSave}>
                        Hesap Açılış İşlemleri
                    </Link> 
                </Menu.Item>
                <Menu.Item key="m3" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.corporationUserSave}>
                        Kullanıcı Tanımlama
                    </Link> 
                </Menu.Item>
                <Menu.Item key="m4">
                    <Link to={urls.corporationsList}>
                        Kurumlar Listesi
                    </Link> 
                </Menu.Item>
                <Menu.Item key="m5">
                    <Link to={urls.riskLimitInformation}>
                        Risk/Limit Bilgileri
                    </Link> 
                </Menu.Item>
            </SubMenu>
            <SubMenu
            key="sub4"
            title={
                <span>
                     <span>Alıcı</span>
                </span>
            }
            >
                <Menu.Item key="m5" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.identification}>
                        Alıcı Tanımlama
                    </Link>                                            
                </Menu.Item>
                <Menu.Item key="m6" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.userIdentificationSave}>
                        Alıcı Kullanıcısı Tanımlama
                    </Link>                                             
                    </Menu.Item>
                <Menu.Item key="m7">
                    <Link to={urls.buyersList}>
                        Alıcılar Listesi
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m8" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.buyerUploadInvoice}>
                        Alıcı Adına Fatura Yükleme
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m13" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.buyerUploadeMustahsil}>
                        Alıcı Adına E-Müstahsil Yükleme
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m9" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.buyerUploadSupplier}>
                        Alıcı Adına Tedarikçi Yükleme
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m10">
                    <Link to={urls.invoicesstatus}>
                        Faturaların Durumu
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m11" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.invoiceMaturityUpdate}>
                        Fatura Vadesi Güncelleme
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m12" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.dummyDataDelete}>
                        Alıcı/Tedarikçi Sil
                    </Link>                                             
                </Menu.Item>
            </SubMenu>

            <SubMenu
            key="sub6"
            title={
                <span>
                     <span>Bildirim Yönetimi</span>
                </span>
            }
            >
                {/* <Menu.Item key="m5" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.templateWatch}>
                        Şablon İzle/Sil
                    </Link>                                            
                </Menu.Item> */}
                <Menu.Item key="m11" className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}>
                    <Link to={urls.templateCreate}>
                        Şablon Oluştur
                    </Link>                                             
                    </Menu.Item>
                <Menu.Item key="m12">
                    <Link to={urls.templateEdit}>
                    Şablon Düzenle / Sil
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m13">
                    <Link to={urls.testMailSend}>
                    Test Maili Gönderme
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m14">
                    <Link to={urls.dataTransfer}>
                    Veri Aktarma
                    </Link>                                             
                </Menu.Item>
            </SubMenu>
           
        </Menu>
    </>
  );
}

export default MobilMenu;
